import React, { useContext, useEffect } from 'react';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { UiContext } from 'uiContext';
import CommonCard from 'components/reviewResultsPage/commonCard/commonCard';
import ResponsibilitiesCard from 'components/reviewResultsPage/responsibilitiesCard/responsibilitiesCard';
import ProjectsCard from 'components/reviewResultsPage/projectsCard/projectsCard';
import Top10SelfWordsCard from 'components/reviewResultsPage/top10SelfWordsCard/top10SelfWordsCard';
import CoworkersReviewCard from 'components/reviewResultsPage/coworkersReviewCard/coworkersReviewCard';
import AvgReviewGradeCard from 'components/reviewResultsPage/avgReviewGradeCard/avgReviewGradeCard';
import AchievementsCard from 'components/reviewResultsPage/achievementsCard/achievementsCard';
import FailuresCard from 'components/reviewResultsPage/failuresCard/failuresCard';
import EmployeeReviewsCard from 'components/reviewResultsPage/employeeReviewsCard/employeeReviewsCard';
import AdvicesCard from 'components/reviewResultsPage/advicesCard/advicesCard';
import Top10IntersectionsCard from 'components/reviewResultsPage/top10IntersectionsCard/top10IntersectionsCard';
import Top10CoworkersWordsCard from 'components/reviewResultsPage/top10CoworkersWordsCard/top10CoworkersWordsCard';
import { sxMixins } from 'utils/uiConstants';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { MobxContext } from 'mobx/mobxContext';
import PageHolder from 'components/pageHolder/pageHolder';
import {
  advicesToAdviceItems,
  respondentsToAdviceItems,
} from 'mobx/employeeForm/_adapters';
import { employeeFormAPI } from 'api/employeeForm';
import CompetenciesCard from "components/reviewResultsPage/competenciesCard/competenciesCard";
import {CompetenciesCardType} from "components/reviewResultsPage/competenciesCard/types";

export default observer(function EmployeeReviewResults() {
  const theme = useTheme();
  const {
    spacings: { sp },
  } = useContext(UiContext);

  const {
    mobxStore: { authInfoStore, employeeFormStore },
  } = useContext(MobxContext);

  const { authError, authInfo } = authInfoStore;

  const navigate = useNavigate();

  const { advicesList, generalAdvicesList } = employeeFormStore;

  const reviewAdvices = advicesList
    ? respondentsToAdviceItems(advicesList)
    : null;
  const generalAdvices = generalAdvicesList
    ? advicesToAdviceItems(generalAdvicesList)
    : null;

  useEffect(() => {
    if (authError || !authInfo?.token) {
      navigate('/auth_fail');
      return;
    }
  }, [authError, authInfo?.token, navigate]);

  return (
    <PageHolder
      sx={{
        height: 1,
        ...sxMixins.getScrollbarStyle({
          scroollableBg: theme.palette.custom.paperBgColor,
          scrollColor: theme.palette.custom.fontColorNotActive,
        }),
        overflowY: 'auto',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingLeft: sp.s120,
          paddingRight: sp.s120,
          marginTop: sp.s200,
          marginBottom: sp.s200,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexWrap: 'wrap',
            textAlign: 'center',
          }}
        >
          <Typography
            variant="f70a"
            sx={{
              textTransform: 'uppercase',
              color: theme.palette.custom.white,
            }}
          >
            результаты review
          </Typography>
          <Typography
            variant="f70a"
            sx={{
              color: theme.palette.custom.white,
              textTransform: 'uppercase',
              marginLeft: sp.s30,
            }}
          >
            по сотруднику
          </Typography>
        </Box>
        <Button
          variant="contained"
          sx={{
            'mt': 4,
            'background': theme.palette.custom.infoCardHeaderColor,
            'boxShadow': 'none',
            'color': theme.palette.custom.fontColor,
            '&:hover': {
              boxShadow: 'none',
              filter: 'brightness(95%)',
            },
          }}
          onClick={async () => {
            if (
              employeeFormStore.employeeData?.id &&
              employeeFormStore.currentReviewPeriodId
            ) {
              try {
                await employeeFormAPI.download(
                  employeeFormStore.employeeData.id,
                  employeeFormStore.currentReviewPeriodId
                );
              } catch (e) {
                console.error(e);
              }
            }
          }}
        >
          <Typography sx={{ marginRight: sp.s10 }}>Выгрузить анкету</Typography>
        </Button>
      </Box>

      <Box
        sx={{
          paddingLeft: sp.s120,
          paddingRight: sp.s120,
        }}
      >
        <CommonCard />

        <AvgReviewGradeCard />

        <ResponsibilitiesCard />

        <ProjectsCard />

        <AchievementsCard />

        <FailuresCard />

        <CompetenciesCard type={CompetenciesCardType.COMMON} />

        <CompetenciesCard type={CompetenciesCardType.MANAGEMENT} />

        <EmployeeReviewsCard />

        <CoworkersReviewCard />

        {reviewAdvices ? (
          <AdvicesCard items={reviewAdvices} header="советы в рамках ревью" />
        ) : null}
        {generalAdvices ? (
          <AdvicesCard items={generalAdvices} header="общие советы" />
        ) : null}

        <Top10IntersectionsCard />

        <Top10SelfWordsCard />

        <Top10CoworkersWordsCard />
      </Box>
    </PageHolder>
  );
});
