import React, { useContext } from 'react';
import {
  Box,
  ListItemText,
  Typography,
  TableRow,
  useTheme,
} from '@mui/material';
import { map } from 'lodash-es';
import InfoCardWrapper from 'components/reviewResultsPage/common/wrappers/infoCardWrapper';
import InfoCardHeader from 'components/reviewResultsPage/common/header/infoCardHeader';
import InfoCardContentWrapper from 'components/reviewResultsPage/common/wrappers/infoCardContentWrapper';
import IconChat from 'icons/chat';
import CombinedItemList from 'components/reviewResultsPage/common/cardLists/combinedItemList/combinedItemList';
import TableWrapper from 'components/reviewResultsPage/common/cardLists/table/tableWrapper';
import TableHeaderWrapper from 'components/reviewResultsPage/common/cardLists/table/tableHeaderWrapper';
import TableCellWrapper from 'components/reviewResultsPage/common/cardLists/table/tableCellWrapper';
import TableBodyWrapper from 'components/reviewResultsPage/common/cardLists/table/tableBodyWrapper';
import ListItemWrapper from 'components/reviewResultsPage/common/cardLists/list/listItemWrapper';
import { observer } from 'mobx-react-lite';
import { MobxContext } from 'mobx/mobxContext';
import { adaptCoworkersReviewData } from 'mobx/employeeForm/_adapters';
import { v4 as uuidv4 } from 'uuid';
import { cantRateText } from 'utils/helpers';

export default observer(function CoworkersReviewCard() {
  const theme = useTheme();

  const {
    mobxStore: { employeeFormStore, authInfoStore, employeeStore },
  } = useContext(MobxContext);

  const { projectsInfo } = employeeFormStore;
  const { authInfo } = authInfoStore;
  const { currentEmployeeId } = employeeStore;

  if (!projectsInfo || !authInfo || !currentEmployeeId) {
    return null;
  }

  const list = adaptCoworkersReviewData(projectsInfo);

  return (
    <InfoCardWrapper>
      <InfoCardHeader
        uppercase
        icon={<IconChat />}
        mainText="оценки от коллег за проекты"
        bgColor={theme.palette.custom.infoCardHeaderColor}
      />

      <InfoCardContentWrapper>
        <CombinedItemList>
          {map(list, (item, index) => {
            const { listItemInfo, tableItemInfo } = item;

            return (
              <React.Fragment key={uuidv4()}>
                <ListItemWrapper
                  isSidePaddingEnabled
                  index={index}
                  bgColor={theme.palette.customBackgrounds.darkBlack}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      width: 1,
                    }}
                  >
                    <ListItemText sx={{ color: theme.palette.custom.white }}>
                      <Typography variant="f24a">
                        {listItemInfo.upperText}
                      </Typography>
                    </ListItemText>
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      width: 1,
                    }}
                  >
                    <ListItemText sx={{ width: 1 }}>
                      <Typography
                        variant="f38a"
                        sx={{ color: theme.palette.custom.white }}
                      >
                        {listItemInfo.mainText}
                      </Typography>
                    </ListItemText>

                    <ListItemText sx={{ width: 1 }}>
                      <Typography
                        variant="f50a"
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          textAlign: 'end',
                          backgroundImage:
                            theme.palette.custom.infoCardHeaderColor,
                          backgroundClip: 'text',
                          textFillColor: 'transparent',
                          WebkitTextFillColor: 'transparent',
                        }}
                      >
                        {listItemInfo.score}
                      </Typography>
                    </ListItemText>
                  </Box>
                </ListItemWrapper>

                <TableWrapper
                  sx={{
                    borderTop: 2,
                    borderTopColor: theme.palette.custom.darkGray,
                  }}
                >
                  <TableHeaderWrapper>
                    {map(tableItemInfo.headerLabels, (label, index) => (
                      <TableCellWrapper
                        key={uuidv4()}
                        text={label}
                        cellSx={{
                          borderRight:
                            index === tableItemInfo.headerLabels.length - 1
                              ? 'none'
                              : 2,
                          borderRightColor: theme.palette.custom.darkGray,
                        }}
                      />
                    ))}
                  </TableHeaderWrapper>

                  <TableBodyWrapper>
                    {map(tableItemInfo.rowsData, (rowItem, index) => (
                      <TableRow
                        key={uuidv4()}
                        sx={{
                          borderTop: 2,
                          borderTopColor: theme.palette.custom.darkGray,
                        }}
                      >
                        {map(rowItem, (text, objectKey) => {
                          if (objectKey === 'score' && text === -1) {
                            text = cantRateText;
                          }
                          if (objectKey === 'fullName') {
                            const commentAuthor = text;

                            return (
                              <TableCellWrapper
                                key={uuidv4()}
                                text={commentAuthor}
                                align="center"
                                cellSx={{
                                  borderLeft: 'none',
                                  borderLeftColor:
                                    theme.palette.custom.darkGray,
                                }}
                              />
                            );
                          } else {
                            return (
                              <TableCellWrapper
                                key={uuidv4()}
                                text={text}
                                align={'center'}
                                cellSx={{
                                  borderLeft: 2,
                                  borderLeftColor:
                                    theme.palette.custom.darkGray,
                                }}
                              />
                            );
                          }
                        })}
                      </TableRow>
                    ))}
                  </TableBodyWrapper>
                </TableWrapper>
              </React.Fragment>
            );
          })}
        </CombinedItemList>
      </InfoCardContentWrapper>
    </InfoCardWrapper>
  );
});
