import React, { useContext } from 'react';
import { Box, Button, Typography, useTheme } from '@mui/material';
import IconICLogo from 'icons/ICLogo';
import IconMskTransportLogo from 'icons/MskTransportLogo';
import { UiContext } from 'uiContext';
import { IconsSizes } from 'hooks/useGetSpacing';
import { useNavigate } from 'react-router-dom';
import { MobxContext } from 'mobx/mobxContext';

export default function Header({ sizeKey }: { sizeKey?: IconsSizes }) {
  const theme = useTheme();

  const {
    mobxStore: { authInfoStore, employeeStore },
  } = useContext(MobxContext);

  const {
    spacings: { sp },
  } = useContext(UiContext);

  const navigate = useNavigate();

  const handleEmployeeFormClick = async () => {
    if (authInfoStore?.authInfo?.user_id) {
      await employeeStore.fetchEmployeeData(
        authInfoStore?.authInfo?.user_id,
        authInfoStore?.authInfo?.fullname
      );
      navigate(`/employee/${authInfoStore?.authInfo?.user_id}`);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: theme.palette.custom.headerBgColor,
        padding: sp.s40,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: sp.s10,
        }}
      >
        <Box>
          <IconICLogo sizeKey={sizeKey} />
        </Box>
        <Box>
          <IconMskTransportLogo sizeKey={sizeKey} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography variant="f14">Безопасный</Typography>
          <Typography variant="f14">транспорт</Typography>
        </Box>
      </Box>

      <Button
        variant="contained"
        sx={{
          'mt': 4,
          'background': theme.palette.custom.infoCardHeaderColor,
          'boxShadow': 'none',
          'color': theme.palette.custom.fontColor,
          '&:hover': {
            boxShadow: 'none',
            filter: 'brightness(95%)',
          },
          'margin': 0,
        }}
        onClick={handleEmployeeFormClick}
      >
        <Typography>Моя анкета</Typography>
      </Button>
    </Box>
  );
}
