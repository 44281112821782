import React, { useContext } from 'react';
import { IconProps } from './iconProps';
import { UiContext } from 'uiContext';
import { defaultSpacingPresets } from 'hooks/useGetSpacing';
import cx from 'classnames';

export default function IconManagementCompetencies({
  onClick,
  isAutoscallable = true,
  sizeKey = '32x32',
  style,
}: IconProps) {
  const {
    spacings: {
      icons: { [sizeKey]: size },
    },
  } = useContext(UiContext);

  const w = isAutoscallable ? size.w : defaultSpacingPresets.icons[sizeKey].w;
  const h = isAutoscallable ? size.h : defaultSpacingPresets.icons[sizeKey].h;

  return (
    <svg
      style={style}
      className={cx('icon IconManagementCompetencies', { 'icon-disabled': !onClick })}
      onClick={onClick}
      width={w}
      height={h}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="32" cy="16.5" r="3.5" fill="white"/>
      <path d="M39 26.5168C39 25.1908 38.2625 23.919 36.9497 22.9813C35.637 22.0436 33.8565 21.5168 32 21.5168C30.1435 21.5168 28.363 22.0436 27.0503 22.9813C25.7375 23.919 25 25.1908 25 26.5168L32 26.5168H39Z" fill="white"/>
      <path d="M31.8801 7.41382C32.1862 7.41382 32.4346 7.16545 32.4346 6.8593V4.55459C32.4346 4.24817 32.1865 4.00008 31.8801 4.00008H17.5545C17.2484 4.00008 17 4.24845 17 4.55459C17 4.86073 17.2484 5.10912 17.5545 5.10912H31.3255V6.8593C31.3255 7.16545 31.5739 7.41382 31.8801 7.41382Z" fill="white" stroke="white" strokeWidth="2"/>
      <path d="M31.8801 32C32.1862 32 32.4346 32.2484 32.4346 32.5545V34.8592C32.4346 35.1656 32.1865 35.4137 31.8801 35.4137H17.5545C17.2484 35.4137 17 35.1654 17 34.8592C17 34.5531 17.2484 34.3047 17.5545 34.3047H31.3255V32.5545C31.3255 32.2484 31.5739 32 31.8801 32Z" fill="white" stroke="white" strokeWidth="2"/>
      <rect x="17" y="19" width="6" height="1" rx="0.5" fill="white" stroke="white" strokeWidth="2"/>
      <path d="M4 5.27874L5.86523 7.34251L9.79 3" stroke="white" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M4 19.2787L5.86523 21.3425L9.79 17" stroke="white" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M4 33.2787L5.86523 35.3425L9.79 31" stroke="white" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}
