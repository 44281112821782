import React, { useContext } from 'react';
import { IconProps } from './iconProps';
import { UiContext } from 'uiContext';
import { defaultSpacingPresets } from 'hooks/useGetSpacing';
import cx from 'classnames';

export default function IconCompetencies({
  onClick,
  isAutoscallable = true,
  sizeKey = '32x32',
  style,
}: IconProps) {
  const {
    spacings: {
      icons: { [sizeKey]: size },
    },
  } = useContext(UiContext);

  const w = isAutoscallable ? size.w : defaultSpacingPresets.icons[sizeKey].w;
  const h = isAutoscallable ? size.h : defaultSpacingPresets.icons[sizeKey].h;

  return (
    <svg
      style={style}
      className={cx('icon IconCompetencies', { 'icon-disabled': !onClick })}
      onClick={onClick}
      width={w}
      height={h}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_781_1404)">
        <rect x="20.5186" y="7.59326" width="4.09" height="0.64" rx="0.32" transform="rotate(90 20.5186 7.59326)" fill="white" stroke="white" strokeWidth="2"/>
        <circle cx="20.2708" cy="5.27084" r="2.27084" stroke="white" strokeWidth="2"/>
        <rect x="10.4434" y="11.0925" width="4.09" height="0.64" rx="0.32" transform="rotate(45 10.4434 11.0925)" fill="white" stroke="white" strokeWidth="2"/>
        <circle cx="8.62567" cy="9.62573" r="2.27084" transform="rotate(-45 8.62567 9.62573)" stroke="white" strokeWidth="2"/>
        <rect x="30.3799" y="11.4434" width="4.09" height="0.64" rx="0.32" transform="rotate(135 30.3799 11.4434)" fill="white" stroke="white" strokeWidth="2"/>
        <circle cx="31.8467" cy="9.62567" r="2.27084" transform="rotate(45 31.8467 9.62567)" stroke="white" strokeWidth="2"/>
        <rect x="33.4062" y="21.519" width="4.09" height="0.64" rx="0.32" transform="rotate(-180 33.4062 21.519)" fill="white" stroke="white" strokeWidth="2"/>
        <circle cx="35.7292" cy="21.2708" r="2.27084" transform="rotate(90 35.7292 21.2708)" stroke="white" strokeWidth="2"/>
        <rect width="4.09" height="0.64" rx="0.32" transform="matrix(1 -4.37114e-08 -4.37114e-08 -1 6.59375 21.519)" fill="white" stroke="white" strokeWidth="2"/>
        <circle cx="3.27084" cy="3.27084" r="2.27084" transform="matrix(0 1 1 0 1 18)" stroke="white" strokeWidth="2"/>
        <circle cx="20" cy="21.8228" r="4.5" fill="white"/>
        <path d="M29 34.7015C29 32.9966 28.0518 31.3614 26.364 30.1558C24.6761 28.9502 22.3869 28.2729 20 28.2729C17.6131 28.2729 15.3239 28.9502 13.636 30.1558C11.9482 31.3614 11 32.9966 11 34.7015L20 34.7015H29Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_781_1404">
          <rect width="40" height="40" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
}
