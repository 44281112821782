import { Auth } from 'mobx/storeTypes';

export const isElementOdd = (index: number) => {
  return index % 2 === 0;
};

export const checkIfRoleAllowRootNavigation = (role: Auth.Roles): boolean => {
  switch (role) {
    case Auth.Roles.Head:
    case Auth.Roles.HR:
    case Auth.Roles.DepartmentHead:
      return true;
    default:
      return false;
  }
};

export const checkIfRoleAllowDepartmentNavigation = (
  role: Auth.Roles
): boolean => {
  switch (role) {
    case Auth.Roles.Head:
    case Auth.Roles.HR:
    case Auth.Roles.SubDepartmentHead:
    case Auth.Roles.DepartmentHead:
      return true;
    default:
      return false;
  }
};

export const cantRateText = 'Не могу оценить';

export const noRateText = '–';
