import React, { useContext } from 'react';
import { map } from 'lodash-es';
import { ListItemText, Typography, useTheme } from '@mui/material';
import InfoCardWrapper from 'components/reviewResultsPage/common/wrappers/infoCardWrapper';
import InfoCardHeader from 'components/reviewResultsPage/common/header/infoCardHeader';
import InfoCardContentWrapper from 'components/reviewResultsPage/common/wrappers/infoCardContentWrapper';
import ListWrapper from 'components/reviewResultsPage/common/cardLists/list/listWrapper';
import ListItemWrapper from 'components/reviewResultsPage/common/cardLists/list/listItemWrapper';
import IconBulb from 'icons/bulb';
import { observer } from 'mobx-react-lite';
import { AdviceListItem } from 'mobx/employeeForm/_adapters';
import { UiContext } from 'uiContext';

export default observer(function AdvicesCard({
  header,
  items,
}: {
  header: string;
  items: AdviceListItem[];
}) {
  const theme = useTheme();

  const {
    spacings: { sp },
  } = useContext(UiContext);

  if (!items || items?.length === 0) {
    return null;
  }

  return (
    <InfoCardWrapper>
      <InfoCardHeader
        uppercase
        icon={<IconBulb />}
        mainText={header}
        bgColor={theme.palette.custom.infoCardHeaderColor}
      />

      <InfoCardContentWrapper>
        <ListWrapper>
          {map(items, ({ mainText, header, commentAuthor }, index) => (
            <ListItemWrapper isSidePaddingEnabled key={index} index={index}>
              <ListItemText sx={{ color: theme.palette.custom.purple }}>
                <Typography
                  variant="f22a"
                  sx={{
                    fontWeight: 'bold',
                  }}
                >
                  {header}
                </Typography>
              </ListItemText>

              <ListItemText>
                <Typography
                  variant="f20a"
                  sx={{ color: theme.palette.custom.white }}
                >
                  {mainText}
                </Typography>
              </ListItemText>

              <ListItemText
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: 1,
                  marginTop: sp.s120,
                }}
              >
                <Typography
                  variant="f22a"
                  align="right"
                  sx={{
                    color: theme.palette.custom.fontColorNotActive,
                    fontWeight: 'bold',
                  }}
                >
                  {commentAuthor}
                </Typography>
              </ListItemText>
            </ListItemWrapper>
          ))}
        </ListWrapper>
      </InfoCardContentWrapper>
    </InfoCardWrapper>
  );
});
