import React, { useContext, useMemo } from 'react';
import {
  Box,
  ListItemText,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { map } from 'lodash-es';
import InfoCardWrapper from 'components/reviewResultsPage/common/wrappers/infoCardWrapper';
import InfoCardHeader from 'components/reviewResultsPage/common/header/infoCardHeader';
import InfoCardContentWrapper from 'components/reviewResultsPage/common/wrappers/infoCardContentWrapper';
import { observer } from 'mobx-react-lite';
import { MobxContext } from 'mobx/mobxContext';
import { v4 as uuidv4 } from 'uuid';
import { CompetenciesCardType } from 'components/reviewResultsPage/competenciesCard/types';
import ListItemWrapper from 'components/reviewResultsPage/common/cardLists/list/listItemWrapper';
import CombinedItemList from 'components/reviewResultsPage/common/cardLists/combinedItemList/combinedItemList';
import TableHeaderWrapper from 'components/reviewResultsPage/common/cardLists/table/tableHeaderWrapper';
import TableCellWrapper from 'components/reviewResultsPage/common/cardLists/table/tableCellWrapper';
import TableWrapper from 'components/reviewResultsPage/common/cardLists/table/tableWrapper';
import { cantRateText, noRateText } from 'utils/helpers';
import TableBodyWrapper from 'components/reviewResultsPage/common/cardLists/table/tableBodyWrapper';
import IconManagementCompetencies from 'icons/managementCompetencies';
import IconCompetencies from 'icons/iconCompetencies';

export default observer(function CompetenciesCard({
  type,
}: {
  type: CompetenciesCardType;
}) {
  const theme = useTheme();

  const {
    mobxStore: { employeeFormStore },
  } = useContext(MobxContext);

  const { totalFormData } = employeeFormStore;

  const data = useMemo(() => {
    if (type === CompetenciesCardType.COMMON) {
      return totalFormData?.competencies;
    }

    return totalFormData?.management_competencies;
  }, [
    totalFormData?.competencies,
    totalFormData?.management_competencies,
    type,
  ]);

  if (!data) {
    return null;
  }

  const tableHeaderLabels =
    type === CompetenciesCardType.COMMON
      ? ['ФИО', 'ОЦЕНКА']
      : ['ФИО', 'ОЦЕНКА', 'КОММЕНТАРИЙ'];

  const prefix =
    type === CompetenciesCardType.MANAGEMENT ? 'Управленческие' : '';

  return (
    <InfoCardWrapper>
      <InfoCardHeader
        uppercase
        icon={
          type === CompetenciesCardType.COMMON ? (
            <IconCompetencies />
          ) : (
            <IconManagementCompetencies />
          )
        }
        mainText={`${prefix} КОМПЕТЕНЦИИ`.toUpperCase()}
        bgColor={theme.palette.custom.infoCardHeaderColor}
      />

      <InfoCardContentWrapper>
        <CombinedItemList>
          {map(data.competencies, (item, index) => (
            <React.Fragment key={uuidv4()}>
              <ListItemWrapper
                isSidePaddingEnabled
                index={index}
                bgColor={theme.palette.customBackgrounds.darkBlack}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    width: 1,
                  }}
                >
                  <ListItemText sx={{ color: theme.palette.custom.white }}>
                    <Typography variant="f24a">
                      {item.name} – {item.description}
                    </Typography>
                  </ListItemText>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    width: 1,
                  }}
                >
                  <ListItemText sx={{ width: 1 }}>
                    <Typography
                      variant="f38a"
                      sx={{ color: theme.palette.custom.white }}
                    >
                      Средняя оценка по компетенции
                    </Typography>
                  </ListItemText>

                  <ListItemText sx={{ width: 1 }}>
                    <Typography
                      variant="f50a"
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        textAlign: 'end',
                        backgroundImage:
                          theme.palette.custom.infoCardHeaderColor,
                        backgroundClip: 'text',
                        textFillColor: 'transparent',
                        WebkitTextFillColor: 'transparent',
                      }}
                    >
                      {item.average}
                    </Typography>
                  </ListItemText>
                </Box>
              </ListItemWrapper>

              <TableWrapper
                sx={{
                  borderTop: 2,
                  borderTopColor: theme.palette.custom.darkGray,
                }}
              >
                <TableHeaderWrapper>
                  {map(tableHeaderLabels, (label, index) => (
                    <TableCellWrapper
                      key={uuidv4()}
                      text={label}
                      cellSx={{
                        borderRight:
                          index === tableHeaderLabels.length - 1 ? 'none' : 2,
                        borderRightColor: theme.palette.custom.darkGray,
                      }}
                    />
                  ))}
                </TableHeaderWrapper>

                <TableBodyWrapper>
                  {map(item.answers, (rowItem) => (
                    <TableRow
                      key={uuidv4()}
                      sx={{
                        borderTop: 2,
                        borderTopColor: theme.palette.custom.darkGray,
                      }}
                    >
                      <TableCellWrapper
                        key={uuidv4()}
                        text={rowItem.fullname}
                        align="center"
                        cellSx={{
                          borderLeft: 'none',
                          borderLeftColor: theme.palette.custom.darkGray,
                        }}
                      />
                      <TableCellWrapper
                        key={uuidv4()}
                        text={
                          rowItem.mark === null
                            ? noRateText
                            : rowItem.mark === -1
                            ? cantRateText
                            : rowItem.mark
                        }
                        align="center"
                        cellSx={{
                          borderLeft: 2,
                          borderLeftColor: theme.palette.custom.darkGray,
                        }}
                      />
                      {type === CompetenciesCardType.MANAGEMENT && (
                        <TableCellWrapper
                          key={uuidv4()}
                          text={rowItem.comment || '-'}
                          align="center"
                          cellSx={{
                            borderLeft: 2,
                            borderLeftColor: theme.palette.custom.darkGray,
                          }}
                        />
                      )}
                    </TableRow>
                  ))}
                </TableBodyWrapper>
              </TableWrapper>
            </React.Fragment>
          ))}
        </CombinedItemList>
      </InfoCardContentWrapper>
    </InfoCardWrapper>
  );
});
